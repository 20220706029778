<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Quotes</a></li>
    </ol>
    <h1 class="page-header">Quotes</h1>
    <panel noButton="true" title="Quotes" variant="panel-default">
      <vue-good-table :columns="columns" :lineNumbers="true" :pagination-options="{ enabled: true, position: 'bottom' }" :rows="forTable" :search-options="{ enabled: true }">
        <div slot="table-actions">
          <download-excel :data="forExport" :title="'Quotes Report'" class="btn btn-primary" name="Quotes.xls">
            <i class="fa fa-file-excel"></i> Export Excel
          </download-excel>
        </div>
      </vue-good-table>
    </panel>
  </div>
</template>

<script>
import Numeral from 'numeral'
export default {
  data() {
    return {
      columns: [
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone ",
          field: "phone"
        },
        {
          label: "Tenure",
          field: "tenure"
        },
        {
          label: "Amount",
          field: "amount"
        },
        {
          label: "Charge",
          field: "charge"
        },
        {
          label: "Requested On",
          field: "requested_on"
        }
      ],
      forTable:[],
      forExport:[],
    }
  },
  computed: {

  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/quotes').then(response => {
        this.quotes = response.data;
        if(response.data){
           response.data.map(row => {
            this.forTable.push({
              'email': row.email,
              'phone': row.phone,
              'tenure': row.tenure,
              'amount': Numeral(row.amount).format("0.00"),
              'charge': Numeral(row.charge).format("0.00"),
              'requested_on': row.created_at,
            });
            this.forExport.push({
              'Email': row.email,
              'Phone': row.phone,
              'Tenure': row.tenure,
              'Amount': Numeral(row.amount).format("0.00"),
              'Charge': Numeral(row.charge).format("0.00"),
              'Requested On': row.created_at
            });
          });
        }
      });
    },
  }
}
</script>